import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  scrollTo() {
    const question = document.getElementById('question');
    if (question) {
      question.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

}
