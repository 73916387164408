import {Component, OnInit} from '@angular/core';
import {BlogPost} from '../shared/types/types';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {BlogService} from '../core/blog.service';
import {MetaService} from "../core/meta.service";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogPosts: BlogPost[] = [];

  constructor(private titleService: Title, private gaService: GoogleAnalyticsService, private metaService: MetaService, private blogService: BlogService) {
    this.metaService.updateMetaTags('EREF fizioterápia - Blog', '/assets/images/blog-og.webp', 'https://www.fizioeref.hu/blog');
    this.gaService.pageView('/blog', 'Blog');

  }

  async ngOnInit() {
    this.blogPosts = await this.blogService.getBlogPosts();
  }
}
