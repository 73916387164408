export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBcxN0bCy_j8wIdMoPlDytsPRWh5-Cl9ZI",
    authDomain: "fizioeref-fb66e.firebaseapp.com",
    projectId: "fizioeref-fb66e",
    storageBucket: "fizioeref-fb66e.appspot.com",
    messagingSenderId: "870012837486",
    appId: "1:870012837486:web:681515b8504a851c09107f",
    measurementId: "G-DVZYVELMY8",
    ssl: false
  }
};
