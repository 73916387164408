<div class="mt-16 p-8 mb-16 intro">
  <div>
    <h2>Blog</h2>
    <div class="divider"></div>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8">
    <app-blog-card class="white-card" *ngFor="let post of blogPosts; index as i;" [blogPost]="post"
                   [ngClass]="{'blog-hidden': i === 2 || i == 3, 'blog-md-hidden': i === 3}">
    </app-blog-card>
  </div>
  <div class="flex justify-center mt-8">
    <a class="primary-button" routerLink="/blog">További cikkek</a>
  </div>
</div>
