<div class="content-space not-found">
  <div class="text">
    <h1>Oops, nem találjuk a keresett oldalt</h1>
    <p class="mb-16">Sajnáljuk, de az általad keresett oldal hiányzik vagy a hozzá vezető link megsérült.</p>
    <a class="primary-button" routerLink="/">Vissza a kezdőoldalra</a>
  </div>
  <div>
    <img src="assets/images/404.webp" alt="not-found">
  </div>
</div>
