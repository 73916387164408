import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public adminService: AdminService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.adminService.getAccessToken()) {
      this.router.navigate(['/admin/belepes']);
      return false;
    }
    return true;
  }
}
