import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'datePipe'
})
export class DatePipePipe implements PipeTransform {

  transform(value: DateTime | undefined, ...args: unknown[]): string | undefined {
    return `${this.getTranslateMonth(value?.monthLong!!)}  ${value?.day}`;
  }

  getTranslateMonth(month: string): string {
    switch (month) {
      case 'January':
        return 'Január'
      case 'February':
        return 'Február'
      case 'March':
        return 'Március'
      case 'April':
        return 'Április'
      case 'May':
        return 'Május'
      case 'June':
        return 'Június'
      case 'July':
        return 'Július'
      case 'August':
        return 'Augusztus'
      case 'September':
        return 'Szeptember'
      case 'October':
        return 'Október'
      case 'November':
        return 'November'
      case 'December':
        return 'December'
      default:
        return month;
    }

  }

}
