<h1 class="seo-title">EREF fizioterápia - Gyógytorna Székesfehérváron</h1>
<div class="header">
  <app-hamburger-menu [isOpen]="isOpen" (changeIsOpen)="isOpen = $event" *ngIf="!isAdmin"></app-hamburger-menu>
  <a class="logo" routerLink="/">
    <img src="assets/icons/a-logo.png" width="180" alt="logo">
  </a>
</div>
<div class="h-line" *ngIf="isAdmin"></div>
<div class="navbar" [ngClass]="{'open': isOpen}" *ngIf="!isAdmin">
  <a routerLink="" [ngClass]="{'active': currentMenu === ''}">Kezdőlap</a>
  <div class="line"></div>
  <a routerLink="munkatarsaink" [ngClass]="{'active': currentMenu === 'munkatarsaink'}">Munkatársaink</a>
  <div class="line"></div>
  <a routerLink="kapcsolat" [ngClass]="{'active': currentMenu === 'kapcsolat'}">Kapcsolat</a>
  <div class="line"></div>
  <a routerLink="informaciok" [ngClass]="{'active': currentMenu === 'informaciok'}">Információk</a>
  <div class="line"></div>
  <a routerLink="szolgaltatasok" [ngClass]="{'active': currentMenu === 'szolgaltatasok'}">Szolgáltatások</a>
  <div class="line"></div>
  <a routerLink="blog" [ngClass]="{'active': currentMenu === 'blog'}">Blog</a>
  <div class="line"></div>
  <a routerLink="videotar" [ngClass]="{'active': currentMenu === 'videotar'}">Videótár</a>
  <div class="line"></div>
  <a routerLink="foglalas" class="reservation-menu" [ngClass]="{'active': currentMenu === 'foglalas'}">Időpont</a>
</div>
