import { Injectable } from '@angular/core';
import { ModalService } from 'angular-modal-simple';
import { SuccessModalComponent } from '../shared/success-modal/success-modal.component';
import { ErrorModalComponent } from '../shared/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private modalService: ModalService) {
  }

  showSuccessModal(message: string, navigateBack?: boolean): void {
    this.modalService.display(SuccessModalComponent, {
      data: {
        message, navigateBack
      }
    })
  }

  showErrorModal(message: string, navigateBack?: boolean): void {
    this.modalService.display(ErrorModalComponent, {
      data: {
        message, navigateBack
      }
    });
  }
}
