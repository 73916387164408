import {Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private homeTitle: string = 'EREF fizioterápia - Gyógytorna Székesfehérváron';
  private homeUrl: string = 'https://www.fizioeref.hu/';
  private homeImage: string = '/assets/images/og-image.jpeg';

  constructor(private titleService: Title, private metaService: Meta) {
  }

  updateMetaTags(title: string, image: string, url: string, description?: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:image', content: image});
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'twitter:url', content: url});
    this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:image', content: image});

    if (description) {
      this.metaService.updateTag({property: 'description', content: description});
      this.metaService.updateTag({property: 'og:description', content: description});
      this.metaService.updateTag({name: 'twitter:description', content: description});
    }
  }

  deleteMetaTags() {
    this.titleService.setTitle(this.homeTitle);
    this.metaService.updateTag({property: 'og:title', content: this.homeTitle});
    this.metaService.updateTag({property: 'og:image', content: this.homeImage});
    this.metaService.updateTag({property: 'og:url', content: this.homeUrl});
    this.metaService.updateTag({property: 'twitter:url', content: this.homeUrl});
    this.metaService.updateTag({name: 'twitter:title', content: this.homeTitle});
    this.metaService.updateTag({name: 'twitter:image', content: this.homeImage});

  }
}
