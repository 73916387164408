export const confirmEmail = (problem: string, time: string): string => {
  return 'Tisztelt Páciensünk!\n\n' +
    'Online foglalás során rögzített időpontját jóváhagytuk. Várjuk szeretettel!\n' +
    `${problem} - ${time}\n\n` +
    'Kérjük, hogy a lefoglalt időpont előtt maximum 5 perccel érkezzen!\n' +
    'Amennyiben első alkalommal keres fel bennünket, kérjük a korábbi leleteit, orvosi papírjait hozza magával!\n' +
    'További információ: https://fizioeref.hu/informaciok\n\n' +
    'Lemondás az alábbi link segítségével, a megadott időpont előtt legfeljebb 24 órával lehetséges: https://fizioeref.hu/foglalas/eventId/lemondas\n' +
    'Egyéb esetben kérjük telefonon vagy a fizioeref@gmail.com e-mail címen jelezze felénk lemondási\n' +
    'szándékát.\n\n' +
    'Üdvözlettel:\n' +
    'EREF fizioterápia csapata';
}


export const declineEmail = (problem: string, time: string): string => {
  return 'Tisztelt Páciensünk!\n\n' +
    'Online foglalás során rögzített időpontja elutasításra került. \n' +
    `${problem} - ${time}\n\n` +
    'Kérjük vegye fel velünk a kapcsolatot telefonon vagy a fizioeref@gmail.com E-mail címen!\n\n' +
    'Köszönettel:\n' +
    'EREF fizioterápia csapata';
}
