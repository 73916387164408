import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReservationService } from '../../core/reservation.service';
import { Doctor, Patient, PendingEvent, Problem } from '../types/types';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { NotificationService } from '../../core/notification.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  patient: Patient = {} as Patient;
  other: string = '';
  moreProblem: string = '';
  agree: boolean = false;

  problem: Problem = new Problem();
  time: DateTime | undefined;
  doctor: Doctor = new Doctor();

  isLoading: boolean = false;

  constructor(private reservationService: ReservationService, private notificationService: NotificationService,
              private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.reservationService.problemSubject.subscribe(resp => this.problem = resp);
    this.reservationService.timeSubject.subscribe(resp => this.time = resp);
    this.reservationService.doctorSubject.subscribe(resp => this.doctor = resp);
  }

  async clickNext() {
    try {
      this.isLoading = true;
      await this.createPendingEvent();
      this.gaService.event('save_reservation', 'reservation', this.problem.name);
      this.next.emit();
    } catch (e) {
      console.log(e);
      this.gaService.event('save_reservation_error', 'reservation', this.problem.name);
      this.notificationService.showErrorModal('Nem sikerült elküldeni a foglalást. Kérjük próbálja újra, vagy kerssen emailben, telefonon bennünket.')
    } finally {
      this.isLoading = false;
    }
  }

  clickBack() {
    this.gaService.event('save_reservation_back', 'reservation');
    this.back.emit();
  }

  async createPendingEvent() {
    const pendingEvent = new PendingEvent(
      this.time, this.problem, this.doctor, this.patient, this.other + this.moreProblem);
    await lastValueFrom(this.reservationService.createPendingEvent(pendingEvent));
  }
}
