import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {
  slideIndex = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.changeSlideImages();
  }

  changeSlideImages() {
    setInterval(() => {
      this.slideIndex++;
      if (this.slideIndex === 5) this.slideIndex = 0;
    }, 5000);
  }

}
