import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {AvailableDoctors, Problem, ProblemList} from '../types/types';
import { ReservationService } from '../../core/reservation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss']
})
export class ProblemComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  selectedProblem: Problem | undefined;
  problemList: Problem[] = ProblemList;

  constructor(private reservationService: ReservationService, private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
  }

  selectProblem(problem: Problem) {
    this.selectedProblem = problem;
  }

  clickNext() {
    if (this.selectedProblem) {
      this.reservationService.problemSubject.next(this.selectedProblem);
      this.gaService.event('select_problem', 'reservation', this.selectedProblem.name);
      this.next.emit();
    }
  }

  clickBack() {
    this.back.emit();
  }

}
