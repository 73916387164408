<div class="content-space">
  <div class="wrapper">
    <div class="row">
      <p>Név</p>
      <p *ngIf="pendingEvent.patient"> {{pendingEvent.patient.name}}</p>
    </div>
    <div class="row">
      <p>Email</p>
      <p *ngIf="pendingEvent.patient">{{pendingEvent.patient.email}}</p>
    </div>
    <div class="row">
      <p>Telefon</p>
      <p *ngIf="pendingEvent.patient"> {{pendingEvent.patient.phoneNumber}}</p>
    </div>
    <div class="row" *ngIf="pendingEvent.other">
      <p>Megjegyzés</p>
      <p> {{pendingEvent.other}}</p>
    </div>
    <div class="row">
      <p>Probléma</p>
      <p *ngIf="pendingEvent.problem"> {{pendingEvent.problem.name}}</p>
    </div>
    <div class="row">
      <p>Időpont</p>
      <input *ngIf="startDate" value="{{startDate.toJSDate()  | date: 'yyyy MM dd HH:mm'}}" (click)="isAccept = undefined" (keyup)="changeStartDate($event)"/>
    </div>
    <div class="row">
      <p>Gyógytornász</p>
      <p *ngIf="pendingEvent.doctor"> {{pendingEvent.doctor.name}}</p>
    </div>
  </div>
  <div class="flex justify-between">
    <p><b>Időpont elfogadása</b></p>
    <div class="admin-select">
      <div [ngClass]="{'active': isAccept}" (click)="setIsAccept(true)">Elfogad</div>
      <div [ngClass]="{'active': !isAccept && isAccept !== undefined}" (click)="setIsAccept(false)">Elutasít</div>
    </div>
  </div>
  <div class="comment" *ngIf="isAccept !== undefined">
    <p>Email:</p>
    <textarea rows="10" [(ngModel)]="emailText"></textarea>
  </div>
  <div class="button-container" *ngIf="!disabledButton">
    <button class="primary-button" [disabled]="isLoadingSaveBtn || isAccept === undefined || this.emailText.length === 0"
            (click)="sendEmail()">
      Küldés
      <app-loading *ngIf="isLoadingSaveBtn" [isWhite]="true"></app-loading>
    </button>
  </div>
</div>
