<div class="question-section">
  <div class="form">
    <div class="form-content">
      <h2>Kérdése van?</h2>
      <div class="divider"></div>
      <!--    TODO validate email address-->
      <input type="email" placeholder="Email cím" [(ngModel)]="email">
      <textarea placeholder="Kérdés" rows="6" [(ngModel)]="text"></textarea>

      <div class="send-button">
        <button class="primary-button" [disabled]="!email || !text || loading" (click)="sendEmail()">Elküld</button>
      </div>
    </div>
  </div>
  <div class="image"></div>
</div>
