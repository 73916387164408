<div class="profile">
  <div class="header">
    <div class="image"></div>
    <div class="info">
      <h2>Meleghegyi Fanni</h2>
      <div class="divider"></div>
      <p class="flex items-center"><img src="assets/icons/doctor.png" alt="doctor" width="20"/>gyógytornász</p>
      <p><a href="tel:+36703603850"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3670/360-3850</a>
      </p>
    </div>
  </div>
  <div class="text-content">
    <p>Meleghegyi Fanni vagyok, gyógytornász-fizioterapeuta. Diplomámat a Debreceni
      Egyetem Népegészségügyi Karán szereztem, 2019-ben. 2019 és 2024 között a
      székesfehérvári Szent György Kórházban dolgoztam főállásban, gyógytornászként. A
      kórházban osztályos feladatokat láttam el, főleg belgyógyászati (kardiológia) és
      intenzív osztályokon szereztem tapasztalatot. Emellett az Agárdi Gyógy- és
      Termálfürdőben végeztem ambuláns gyógytorna ellátást, 2020 október és 2021
      szeptembere között, ahol számos mozgásszervi problémával találkoztam.
      <br/><br/>
      Gyógytornászként fontosnak tartom a konzervatív kezelési lehetőségek
      hangsúlyozását a páciensek körében. Véleményem szerint nincs annál jobb terápia,
      mint amikor saját magunk tehetünk az egészségünk megőrzéséért vagy éppen
      visszaállításáért. A gyógytorna erre egy kiváló eszköz, amennyiben jó időben és
      megfelelően, szakember segítségével alkalmazzuk.<br/><br/>

      Célom, hogy a hozzám forduló páciensek megértsék az adott betegséget, állapotot
      és rálátást kapjanak annak kezelésére, így tudatosan elérve a kitűzött célt.</p>
    <p>Amiben segítségére lehetek:</p>
    <ul>
      <li>Gerincbántalmak, mozgásszervi fájdalmak kezelése</li>
      <li> Műtét előtti felkészítés</li>
      <li> Műtét utáni rehabilitáció</li>
      <li>Neurológiai kórképek fizioterápiás kezelése</li>
      <li>Belgyógyászati fizioterápia ( kardiológia, pulmonológia)</li>
      <li>Dynamic- és kinezio tape felhelyezés (kiegészítő terápia)</li>
    </ul>
    <p>Továbbképzések:</p>
    <ul>
      <li>McKenzie- Mechanikai diagnózis és terápia, A (lumbális gerinc), B (nyaki-és háti gerinc)</li>
      <li>Neurodinamika – A perifériás neurogén panaszok funkcióvizsgálata és kezelése</li>
      <li>Dynamic tape</li>
      <li>Kinesiotape</li>
    </ul>
  </div>
</div>
