import { Injectable } from '@angular/core';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BlogPost } from '../shared/types/types';
import { HttpClient } from "@angular/common/http";
import { BLOCKS } from "@contentful/rich-text-types";
import { marked } from "marked";

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private blogPosts: BlogPost[] = [];

  constructor(private httpClient: HttpClient) {
  }

  getNormalizedText(text: string): string {
    return text.normalize("NFD")
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, ' ')
      .split(' ')
      .join('-')
      .replace(/-+/g, '-')
  }

  async getBlogPosts(): Promise<BlogPost[]> {
    this.blogPosts = await this.getBlogPostFromContentful();
    this.blogPosts.sort((a, b) => b.id - a.id);
    return this.blogPosts;
  }

  getRandomBlogPosts(excludeTitle: string): BlogPost[] {
    return this.blogPosts.filter(post => post.title !== excludeTitle);
  }

  getBlogPostByTitle(title: string): BlogPost | undefined {
    return this.blogPosts.find(post => this.getNormalizedText(post.title) === title);
  }

  async getBlogPostFromContentful(): Promise<BlogPost[]> {
    const postList = await this.httpClient.get(
      'https://cdn.contentful.com/spaces/0yl50763pf02/environments/master/entries?access_token=q1y1kgfFDay3TtOOGa0_1AH4LzOld_soRc9yW41-LGs&content_type=blog').toPromise();

    return (postList as any).items.map((post: any) => {
        const imageId = post.fields.image.sys.id;
        const image = (postList as any).includes.Asset.find((asset: any) => asset.fields.file.url.includes(imageId));
        const markedDescription = marked.parse(post.fields.description) as string;
        const content = documentToHtmlString(post.fields.content, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
            [BLOCKS.HEADING_2]: (node, next) => `<br /><br /><h2>${next(node.content)}</h2>`
          }
        });

        return new BlogPost(post.fields.title, markedDescription, content, image.fields.file.url, post.fields.id);
      }
    );
  }


}
