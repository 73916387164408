import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingEvent } from '../../reservation/types/types';
import { ReservationService } from '../../core/reservation.service';
import { lastValueFrom } from 'rxjs';
import { AdminService } from '../../core/admin.service';
import { NotificationService } from '../../core/notification.service';
import { confirmEmail, declineEmail } from '../types/types';
import { DatePipePipe } from '../../shared/pipe/date-pipe.pipe';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit {
  eventId: string | null = '';
  pendingEvent: PendingEvent = {} as PendingEvent;
  startDate: DateTime | undefined;
  emailText: string = '';
  loginEmail: string = '';
  disabledButton: boolean = false;
  isLoadingSaveBtn: boolean = false;
  isAccept: boolean | undefined;

  constructor(private activatedRoute: ActivatedRoute,
              private reservationService: ReservationService,
              private adminService: AdminService,
              private router: Router, private notificationService: NotificationService,
              private datePipe: DatePipePipe) {
  }

  ngOnInit(): void {
    this.eventId = this.activatedRoute.snapshot.paramMap.get('id');
    this.loginEmail = this.adminService.getEmail();
    this.isLoadingSaveBtn = true;
    this.getEventDetails();
  }

  async getEventDetails() {
    if (this.eventId) {
      const pendingEvent$ = this.reservationService.getEventById(this.eventId);
      const event = await lastValueFrom(pendingEvent$);

      if (event.status === 'cancelled') {
        this.notificationService.showErrorModal('A link érvénytelen. Ezt az eseményt már elfogadták vagy elutasították.', true);
      }

      this.pendingEvent = JSON.parse(event.description) as PendingEvent;
      this.startDate = DateTime.fromISO(this.pendingEvent.startTime);
      this.isLoadingSaveBtn = false;
    }
  }

  async sendEmail() {
    this.isAccept ? await this.acceptPendingEvent() : await this.cancelPendingEvent()
  }

  async acceptPendingEvent() {
    // TODO different doctor appointment
    if (this.eventId && this.pendingEvent) {
      this.isLoadingSaveBtn = true;
      const acceptEvent$ = this.reservationService.acceptPendingEvent(this.eventId, this.pendingEvent, this.emailText);
      try {
        await lastValueFrom(acceptEvent$);
        this.notificationService.showSuccessModal('Az előzetes időpont foglalás elfogadásra került.');
        this.disabledButton = true;
      } catch (e) {
        if ((e as any).message === 'unauthenticated') {
          this.notificationService.showErrorModal('Kérjük jelentkezz be újra a megfelelő fiókoddal.');
          this.router.navigate(['./admin/belepes']);
        }
      } finally {
        this.isLoadingSaveBtn = false;
      }
    }
  }

  async cancelPendingEvent() {
    if (this.eventId && this.pendingEvent) {
      this.isLoadingSaveBtn = true;
      try {
        const declineEvent$ = this.reservationService.declinePendingEvent(this.eventId, this.pendingEvent, this.emailText);
        await lastValueFrom(declineEvent$);
        this.notificationService.showSuccessModal('Az előzetes időpont elutasításra került.');
        this.disabledButton = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoadingSaveBtn = false;
      }
    }

  }

  setIsAccept(accept: boolean) {
    this.isAccept = accept;

    const startDateTime = DateTime.fromISO(this.pendingEvent.startTime);
    const time = `${startDateTime.year} ${this.datePipe.transform(startDateTime)!}, ${startDateTime.hour}:${('0' + startDateTime.minute).slice(-2)}`;

    this.emailText = this.isAccept ?
      confirmEmail(this.pendingEvent.problem.name, time)
      : declineEmail(this.pendingEvent.problem.name, time);
  }

  changeStartDate(event: any) {
    const newDate = DateTime.fromFormat(event.target.value, 'yyyy MM dd HH:mm');
    this.pendingEvent.startTime = newDate.toString();
    this.pendingEvent.endTime = newDate.plus({minute: this.pendingEvent.problem.duration}).toString();
  }

  // TODO delete access_token

}
