import { DateTime } from 'luxon';

export class Doctor {
  name: string = '';
  email: string = '';
}

export const AvailableDoctors: Doctor[] = [
  {name: 'Enesei Rita', email: 'perlaritta@gmail.com'},
  {name: 'Kaufer Barbara', email: 'kaufer.barbara@gmail.com'},
  // {name: 'Enesei Lilla', email: 'enesei.lilla@gmail.com'},
]

export class Problem {
  name: string = '';
  doctor: Doctor[] = [];
  icon: string = '';
  duration: number = 0;
}

export const ProblemList: Problem[] = [
  {
    name: 'Gerincfájdalom (derék, hát, nyak), végtagokba kisugárzó fájdalmak, McKenzie terápia',
    doctor: [AvailableDoctors[0]],
    icon: 'spine.png',
    duration: 60
  },
  {
    name: 'Mozgásszervi megbetegedések, ízületi bántalmak, tartáshibák',
    doctor: [AvailableDoctors[0]],
    icon: 'leg.png',
    duration: 60
  },
  {
    name: 'Sportsérülés',
    doctor: [AvailableDoctors[0]],
    icon: 'sport.png',
    duration: 60
  },
  {
    name: 'Műtétek utáni fizioterápia',
    doctor: [AvailableDoctors[0]],
    icon: 'hospital.png',
    duration: 60
  },
  {
    name: 'Neurológiai kórképek (Stroke, Parkinson-kór, Sclerosis multiplex stb.)',
    doctor: [AvailableDoctors[0]],
    icon: 'brain.png',
    duration: 60
  },
  {
    name: 'Belgyógyászati betegségek (szívbetegség, légzőszervi megbetegedés, poszt-covid)',
    doctor: [],
    icon: 'heart.png',
    duration: 60
  },
  {
    name: 'Masszázs (60 perc)',
    doctor: [AvailableDoctors[1]],
    icon: 'body-massage.png',
    duration: 60
  },
  {
    name: 'Masszázs (30 perc)',
    doctor: [AvailableDoctors[1]],
    icon: 'massage-30.png',
    duration: 30
  },
  {
    name: 'Egyéb',
    doctor: [AvailableDoctors[0]],
    icon: 'other.png',
    duration: 60
  }
];

export class Day {
  day: DateTime = DateTime.local();
  time: TimeSlot[] = [];
}

export class TimeSlot {
  hour: number = 0;
  disabled: boolean = false;
  doctor: Doctor[] = [];
}

export class Patient {
  name: string = '';
  email: string = '';
  phoneNumber: string = '';
}

export class PendingEvent {
  startTime: string = '';
  endTime: string = '';
  doctor: Doctor = {} as Doctor;
  problem: Problem = {} as Problem;
  patient: Patient = {} as Patient;
  other: string = '';

  constructor(time: DateTime | undefined, problem: Problem, doctor: Doctor, patient: Patient, other: string) {
    this.startTime = time?.toISO() || '';
    this.endTime = time?.plus({minute: problem.duration}).toISO() || '';
    this.doctor = doctor;
    this.problem = problem;
    this.patient = patient;
    this.other = other;
  }
}
