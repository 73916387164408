<div class="text-center">
  <div>
    <h1>Válassza ki az alkalmas időpontot!</h1>
    <p class="my-4">Az időpontfoglalás csak előzetes, annak elfogadásáról e-mail értesítést küldünk Önnek!</p>
    <div class="header-btn" *ngIf="startWeekDate">
      <button class="default-button arrow-btn" (click)="showPreviousWeek()"
              [disabled]="today.diff(startWeekDate).milliseconds > 0">＜
      </button>
      <b>{{startWeekDate | datePipe}} - {{endWeekDate | datePipe}}</b>
      <button class="default-button arrow-btn" (click)="showNextWeek()">＞</button>
      <button class="default-button today-btn" (click)="showCurrentWeek()">Ma</button>
    </div>
  </div>

  <div class="week">
    <app-loading *ngIf="isLoading"></app-loading>
    <div class="week-day" *ngFor="let date of weekDays">
      <p>{{date.day.weekdayLong | dayPipe}} </p>
      {{date.day.month |  number: '2.0-0'}} . {{date.day.day | number: '2.0-0'}}
      <div class="h-line"></div>
      <div class="times">
        <div class="time-column">
          <div class="time-slot"
               [ngClass]="{'selected': date.day.toISODate() === selectedDate?.toISODate() && time.hour === selectedTime,
               'disabled': time.disabled || isLoading}"
               (click)="selectTime(date.day, time)"
               *ngFor="let time of date.time">{{time.hour}}:00
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="doctor-info" *ngIf="availableDoctors && selectedDoctor">
    <b>Gyógytornász: </b>
    <select #doctorSelect [ngModel]="selectedDoctor.email" (change)="selectDoctor(doctorSelect.value)">
      <option [value]="doctor.email" *ngFor="let doctor of availableDoctors">{{doctor.name}}</option>
    </select>
  </div>
</div>
<div class="btn-container">
  <button class="default-button" (click)="clickBack()">Vissza</button>
  <button class="primary-button" [disabled]="!selectedDate && !selectedTime" (click)="clickNext()">Tovább</button>
</div>
