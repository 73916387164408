<div class="m-8 sm:m-0 sm:mt-8" *ngIf="blogPost">
  <div>
    <h1 class="mb-8">
      {{blogPost.title}}
    </h1>
  </div>
  <div class="grid md:grid-cols-3 md:gap-4 relative">
    <div class="text-box"></div>
    <div class="picture-mask col-span-2">
      <img [src]="blogPost.image" alt="blog-image">
    </div>
    <div class="text" [innerHTML]="blogPost.description">
    </div>
  </div>
  <div class="blog-content" [innerHTML]="blogPost.content">
  </div>
  <div>
    <div class="line"></div>
    <h3 class="mt-4">További cikkek:</h3>
    <div class="grid md:grid-cols-2">
      <app-blog-card *ngFor="let post of blogPosts; let i = index" [ngClass]="{'md:border-r-2': i === 0}"
                     [blogPost]="post"
                     [isSmallView]="true"
      ></app-blog-card>
    </div>
  </div>
</div>
