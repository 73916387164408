import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogPostComponent } from "./blog/blog-post/blog-post.component";
import { MainComponent } from './main/main.component';
import { ContactComponent } from './contact/contact.component';
import { InfoComponent } from './info/info.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services/services.component';
import { ReservationComponent } from './reservation/reservation.component';
import { AdminComponent } from './admin/admin.component';
import { ReservationDetailsComponent } from './admin/reservation-details/reservation-details.component';
import { AuthGuardService } from './core/auth-guard.service';
import { CancelComponent } from './cancel/cancel.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlogComponent } from './blog/blog.component';
import {VideoComponent} from "./video/video.component";

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'kapcsolat', component: ContactComponent },
  { path: 'informaciok', component: InfoComponent },
  { path: 'munkatarsaink', component: AboutUsComponent },
  { path: 'munkatarsaink/:name', component: AboutUsComponent },
  { path: 'szolgaltatasok', component: ServicesComponent },
  { path: 'foglalas', component: ReservationComponent },
  { path: 'foglalas/:id/lemondas', component: CancelComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:title', component: BlogPostComponent },
  { path: 'videotar', component: VideoComponent },
  { path: 'admin/belepes', component: AdminComponent },
  { path: 'admin/foglalas/:id', component: ReservationDetailsComponent,
    canActivate: [AuthGuardService]
  },
  { path: '**',  component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
