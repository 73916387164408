import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  metaElement: HTMLMetaElement | null | undefined;

  constructor(private titleService: Title, private metaService: Meta) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('EREF fizioterápia - Az oldal nem található');
    this.metaElement = this.metaService.addTag({name: 'robots', content: 'noindex'});
  }

  ngOnDestroy(): void {
    this.metaService.removeTagElement(this.metaElement!);
  }

}
