<div class="circle">
  <img src="assets/icons/cancel.png" width="20" alt="cancel-icon"/>
</div>
<div class="error-modal">
  <div class="title">
    <b>Oops!</b>
  </div>
  <p>{{message}}</p>
  <button (click)="close()" class="default-button">Rendben</button>
</div>
