<div class="circle">
  <img src="assets/icons/check-mark.png" width="20" alt="check-icon"/>
</div>
<div class="success-modal">
  <div class="title">
    <b>Yoo!</b>
  </div>
  <p>{{message}}</p>
  <button (click)="close()" class="default-button">Rendben</button>
</div>
