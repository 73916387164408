import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {Service} from "../shared/types/types";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private httpClient: HttpClient) {
    this.getServiceList();
  }

  async getServiceList() {
    const serviceList = await this.httpClient.get(
      'https://cdn.contentful.com/spaces/0yl50763pf02/environments/master/entries?access_token=q1y1kgfFDay3TtOOGa0_1AH4LzOld_soRc9yW41-LGs&content_type=service').toPromise();

    return (serviceList as any).items.map((service: any) =>
      new Service(service.fields.label, documentToHtmlString(service.fields.description), service.fields.order))
      .sort((a: Service, b: Service) => {
        return a.order - b.order;
      });
  }
}
