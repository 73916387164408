<a [routerLink]="'/blog/' + url">
  <div class="card" [ngClass]="{'md:flex': isSmallView}">
    <div class="picture-mask" [ngClass]="isSmallView ? 'pb-20 md:pb-0 md:mx-4' : 'pb-40 md:pb-64'">
      <img [src]="blogPost.image" alt="blog-image">
    </div>
    <div class="title-box" [ngClass]="isMainPage ? 'px-2': ''">
      <h4>{{blogPost.title}}</h4>
      <p [innerHTML]="blogPost.description"></p>
    </div>
  </div>
</a>
