import { Injectable } from '@angular/core';
import { Doctor, PendingEvent, Problem } from '../reservation/types/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  problemSubject: BehaviorSubject<Problem> = new BehaviorSubject<Problem>({} as Problem);
  timeSubject: BehaviorSubject<DateTime> = new BehaviorSubject<DateTime>({} as DateTime);
  doctorSubject: BehaviorSubject<Doctor> = new BehaviorSubject<Doctor>({} as Doctor)

  constructor(private fns: AngularFireFunctions, private adminService: AdminService) {
    // this.fns.useFunctionsEmulator('http://localhost:5001');
  }

  getEvents(timeMin: DateTime, timeMax: DateTime): Observable<any> {
    const callable = this.fns.httpsCallable('getEvents');
    return callable({timeMin: timeMin.toJSDate().toISOString(), timeMax: timeMax.toJSDate().toISOString()});
  }

  createPendingEvent(pendingEvent: PendingEvent): Observable<any> {
    const callable = this.fns.httpsCallable('createPendingEvents');
    return callable(pendingEvent);
  }

  acceptPendingEvent(eventId: string, pendingEvent: PendingEvent, comment: string): Observable<any> {
    const callable = this.fns.httpsCallable('acceptPendingEvent');
    return callable({eventId, pendingEvent, token: this.adminService.getAccessToken(), comment});
  }

  declinePendingEvent(eventId: string, pendingEvent: PendingEvent, comment: string): Observable<any> {
    const callable = this.fns.httpsCallable('declinePendingEvent');
    return callable({eventId, pendingEvent, comment});
  }

  getEventById(eventId: string): Observable<any> {
    const callable = this.fns.httpsCallable('getEventById');
    return callable({eventId});
  }

  deleteEvent(event: any, doctor: string, patient: string): Observable<any> {
    const callable = this.fns.httpsCallable('deleteEvent');
    return callable({event, doctor, patient});
  }
}
