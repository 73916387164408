<div class="content-space">
  <div class="flex flex-wrap mb-4">
    <p class="pr-2 py-1 font-bold">Kategóriák: </p>
    <p class="filter" *ngFor="let category of categories" (click)="setActiveFilter(category)"
       [ngClass]="{'active': activeFilter === category}">
      {{category | uppercase}}
    </p>
  </div>
  <div class="line"></div>
  <div *ngIf="filteredVideos.length > 0" class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
    <div class="flex flex-col w-full py-4 video" [ngClass]="{'bg-secondary-light px-4': i === 0 && activeFilter === ''}"
         *ngFor="let video of filteredVideos; let i = index">
      <div class="video-container">
        <iframe class="w-full h-full absolute top-0 left-0" [src]="video.link"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
        </iframe>
      </div>
      <div class="my-4 mx-0">
        <h2>{{video.title}}</h2>
        <p>{{video.description}}</p>
      </div>
    </div>
  </div>
</div>
