import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'angular-modal-simple';
import { Router } from "@angular/router";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit, OnDestroy {
  @Input()
  message: string = '';

  @Input()
  navigateBack: boolean = false;

  constructor(private modalService: ModalService, private router : Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.navigateBack) {
      this.router.navigate(['./']);
    }
  }

  close() {
    this.modalService.close(ErrorModalComponent);
  }
}
