import { Component, OnInit } from '@angular/core';

import{ init, send } from 'emailjs-com';
init("user_XWOQ5YoYrFJNvXVWpS0jQ");

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  email: string = '';
  text: string = '';
  loading: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  sendEmail(): void {
    const templateParams = {
      clientEmail: this.email,
      message: this.text
    };

    this.loading = true;

    send('service_jareqdo', 'template_jpjviik', templateParams)
      .then(resp =>  {
        this.email = '';
        this.text = '';
        this.loading = false;
        alert("Kérdését megkaptuk, hamarosan válaszolunk!");
      }, err => {
        this.loading = false;
        alert("Valami probléma adódott, kérjük próbálja meg újra.");
      });
  }
}
