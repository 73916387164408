<div class="content-space">
  <h2>Miben tudunk segíteni?</h2>
  <div class="divider"></div>
  <div class="collapsible">
    <ng-container *ngFor="let service of services; index as i">
      <div class="header" (click)="showContent(i + 1)">
        <div class="square">
          <a class="arrow-icon" [ngClass]="{'open': contentIndex === i + 1}">
            <span class="left-bar"></span>
            <span class="right-bar"></span>
          </a>
        </div>
        {{ service.label }}
      </div>
      <div class="text" [ngClass]="{'show': contentIndex === i + 1}" [innerHTML]="service.description">
      </div>
      <div class="line"></div>

    </ng-container>
  </div>
</div>
