import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { BlogPost } from '../types/types';
import { BlogService } from '../../core/blog.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {
  @Input() blogPost: BlogPost = {} as BlogPost;
  @Input() isSmallView: boolean = false;
  isMainPage: boolean = false;
  url: string = '';

  constructor(private blogService: BlogService, private activatedRoute: ActivatedRoute) {
    this.isMainPage = this.activatedRoute.snapshot.url.length === 0;
  }

  ngOnInit(): void {
    this.url = this.blogService.getNormalizedText(this.blogPost.title);
  }
}
