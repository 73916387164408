import { Component, OnInit } from '@angular/core';
import { BlogPost } from '../../shared/types/types';
import { BlogService } from '../../core/blog.service';

@Component({
  selector: 'app-blog-intro',
  templateUrl: './blog-intro.component.html',
  styleUrls: ['./blog-intro.component.scss']
})
export class BlogIntroComponent implements OnInit {

  blogPosts: BlogPost[] = [];

  constructor(private blogService: BlogService) { }

  async ngOnInit() {
    this.blogPosts = (await this.blogService.getBlogPosts()).slice(0, 4);
  }

}
