<div class="content-space">
  <div class="profiles">
    <div class="profile-card">
      <div class="image image-1"></div>
      <div class="info">
        <div>
          <h2>Enesei Rita</h2>
          <div class="divider"></div>
          <p class="flex items-center"><img src="assets/icons/doctor.png" alt="doctor" width="20"/>gyógytornász</p>
          <p><a href="tel:+36308336933"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/833-6933</a>
          </p>
        </div>
        <a class="primary-button" routerLink="/munkatarsaink/enesei-rita">Bemutatkozás</a>
      </div>
    </div>
    <div class="profile-card">
      <div class="image image-2"></div>
      <div class="info">
        <div>
          <h2>Meleghegyi Fanni</h2>
          <div class="divider"></div>
          <p class="flex items-center"><img src="assets/icons/doctor.png" alt="doctor" width="20"/>gyógytornász</p>
          <p><a href="tel:+36703603850"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3670/360-3850</a>
          </p>
        </div>
        <a class="primary-button" routerLink="/munkatarsaink/meleghegyi-fanni">Bemutatkozás</a>
      </div>
    </div>
    <div class="profile-card">
      <div class="image image-3"></div>
      <div class="info">
        <div>
          <h2>Kaufer Barbara</h2>
          <div class="divider"></div>
          <p class="flex items-center"><img src="assets/icons/doctor.png" alt="doctor" width="20"/>masszőr</p>
          <p><a href="tel:+36304068473"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/406-8473</a>
          </p>
        </div>
        <a class="primary-button" routerLink="/munkatarsaink/kaufer-barbara">Bemutatkozás</a>
      </div>
    </div>
  </div>
</div>
