<div class="content-space">
  <div class="bs-stepper">
    <div class="bs-stepper-header">
      <div class="step" data-target="#problem">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex === 0">1</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex >= 1">✔</span>
          <span class="bs-stepper-label">Probléma</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#time">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex <= 1">2</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex > 1">✔</span>
          <span class="bs-stepper-label">Időpont</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#data">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex <= 2">3</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex > 2">✔</span>
          <span class="bs-stepper-label">Adatok</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#confirm">
        <button class="step-trigger">
          <span class="bs-stepper-circle">4</span>
          <span class="bs-stepper-label">Vége</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div id="problem" class="content">
        <app-problem (next)="next()" (back)="back()"></app-problem>
      </div>
      <div id="time" class="content">
        <app-time (next)="next()" (back)="back()"></app-time>
      </div>
      <div id="data" class="content text-center">
        <app-data (next)="next()" (back)="back()"></app-data>
      </div>
      <div id="confirm" class="content text-center">
        <div class="confirm-content">
          <h1 class="mb-8">Időpont foglalás elküldve!</h1>
          <p>Az előzetes időpontfoglalás sikeresen megtörtént, erről e-mail értesítést küldtünk. Időpontja az
            elfogadás után válik véglegessé, melyet 24 órán belül újabb e-mailben küldünk meg Önnek.
            Addig is szíves türelmét kérjük!</p>
          <p class="my-2">Probléma vagy kérdés esetén keressen minket a <a class="inline">fizioeref@gmail.com</a> címen.</p>
        </div>
      </div>
    </div>
  </div>

</div>
