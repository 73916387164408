import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Video} from "../shared/types/types";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private httpClient: HttpClient) {
  }

  async getVideosFromContentful(): Promise<Video[]> {
    const videoList = await this.httpClient.get(
      'https://cdn.contentful.com/spaces/0yl50763pf02/environments/master/entries?access_token=q1y1kgfFDay3TtOOGa0_1AH4LzOld_soRc9yW41-LGs&content_type=content&fields.menu=Videótár&include=3').toPromise();

    const entries = (videoList as any).includes.Entry;
    return (videoList as any).items.map((video: any) => {
        return video.fields.content.map((content: { sys: { id: string; }; }) => {
          const foundedVideo = entries.find((item: {
            sys: { id: string; };
          }) => item.sys.id === content.sys.id)

          const categoryList = foundedVideo.fields.categories?.map((category: any) => {
            return entries.find((item: {
              sys: { id: string; };
            }) => item.sys.id === category.sys.id).fields.category.toLowerCase()
          });

          return new Video(foundedVideo.fields.title, foundedVideo.fields.description, foundedVideo.fields.link, categoryList);
        });
      }
    )[0].reverse();
  }
}
