import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private accessToken: string = '';
  private loginEmail: string = '';

  constructor() {
    this.accessToken = localStorage.getItem('token') ?? '';
    this.loginEmail = localStorage.getItem('email') ?? '';
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem('token', token);
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  setEmail(email: string) {
    this.loginEmail = email;
    localStorage.setItem('email', email);
  }

  getEmail(): string {
    return this.loginEmail;
  }
}
