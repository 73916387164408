  <div class="sm:flex justify-between content-space w-full">
    <div class="bg-new w-full p-8">
      <h2>
        Költözünk !
      </h2>
      <div class="divider"></div>
      <div>
        <b>Augusztus 1-től</b> új helyszínen várjuk leendő és meglévő pácienseinket!
        <br/><b>Cím:</b> Székesfehérvár Budai út 46. 2/5.
      </div>
      <a class="underline" routerLink="/kapcsolat">Részletek →</a>
    </div>
  </div>
<div class="content content-space">
  <div class="box">
    <img src="assets/icons/location.png" alt="location">
    <div>
      <h2>Helyszín</h2>
      <p>Székesfehévári magánrendelőnk címe.</p>
    </div>
    <a
      href="https://www.google.com/maps/place/Sz%C3%A9kesfeh%C3%A9rv%C3%A1r,+Kir%C3%A1ly+sor+30,+8000/@47.1956866,18.4231651,17z/data=!3m1!4b1!4m5!3m4!1s0x4769f7b946391719:0xbb302e478ff6a7ab!8m2!3d47.195683!4d18.4253538"
      class="primary-button" target="_blank">Katt</a>
  </div>
  <div class="box">
    <img src="assets/icons/calendar.png" alt="calendar">
    <div>
      <h2>Időpontfoglalás</h2>
      <p>Gyógytorna és masszázs alkalomra időpont foglalása egyszerűen.</p>
    </div>
    <a class="primary-button" routerLink="foglalas">Katt</a>
  </div>
  <div class="box">
    <img src="assets/icons/question.png" alt="question">
    <div>
      <h2>Kérdése van?</h2>
      <p>Segítünk, forduljon hozzánk bizalommal.</p>
    </div>
    <button class="primary-button" (click)="scrollTo()">Katt</button>
  </div>
</div>
