<div class="profile">
  <div class="header">
    <div class="image"></div>
    <div class="info">
      <h2>Kaufer Barbara</h2>
      <div class="divider"></div>
      <p class="flex items-center"><img src="assets/icons/doctor.png" alt="doctor" width="20"/>masszőr</p>
      <p><a href="tel:+36304068473"><img src="assets/icons/phone.png" alt="phone" width="20"/>+3630/406-8473</a>
    </div>
  </div>
  <div class="text-content">
    <p>Kaufer Barbara vagyok, bio- és vegyészmérnök diplomát szereztem a veszprémi Pannon
      Egyetemen. Középiskola után emellett döntöttem, azonban a tanulmányi éveim előre haladtával
      egyre inkább világossá vált bennem a gondolat, hogy ez nem teljesen az én Utam. Fokozatosan
      kikristályosodott bennem, hogy az emberekért és egészségükért szeretnék tenni úgy, hogy a
      mindenkori állapotukat masszírozással kezeljem.
      <br/>2020-ban elvégeztem a svédmasszőr képzést, valamint - hogy minél hatékonyabban tudjam
      kezelni a pácienseim mozgásszervi problémáit -, oklevelet szereztem kinesio – tape technikából.
      Szakmai tudásom bővítése érdekében 2023. szeptemberétől a gyógymasszőri képzést tanulom.
      Első éves gyakorlatomat a Fejér Vármegyei Szent György Egyetemi Oktató Kórházban
      teljesítettem.
      <br/><br/> Hitem és meggyőződésem, hogy egészségünk megőrzéséhez a sporton és a megfelelő
      táplálkozáson kívül testünk karbantartása, regenerálása is legalább olyan fontos tényező.</p>
  </div>
</div>
