import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ModalService } from 'angular-modal-simple';
import {Router} from "@angular/router";

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit, OnDestroy {
  @Input()
  message: string = '';

  @Input()
  navigateBack: boolean = false;

  constructor(private modalService: ModalService, private router : Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.navigateBack) {
      this.router.navigate(['./']);
    }
  }

  close() {
    this.modalService.close(SuccessModalComponent);
  }
}
