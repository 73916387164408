import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AvailableDoctors } from '../reservation/types/types';
import { AdminService } from '../core/admin.service';
import { Location } from '@angular/common';
import { NotificationService } from '../core/notification.service';
import { Title } from '@angular/platform-browser';

declare const gapi: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  doctors = AvailableDoctors;

  constructor(private adminService: AdminService, private location: Location,
              private notificationService: NotificationService, private titleService: Title) {
  }


  ngOnInit() {
    this.titleService.setTitle('EREF fizioterápia - Admin oldal');
  }

  ngAfterViewInit() {
    this.googleInit()
  }

  async googleInit() {
    await gapi.load('auth2', async () => {
      await gapi.auth2.init({
        client_id: '426758567052-0rp8j6jbdsc5582t357c9tqggr6bo4ur.apps.googleusercontent.com',   // Mention the client_id created in google developer console
        cookiepolicy: 'single_host_origin',
        prompt: 'select_account',
        scope: 'https://www.googleapis.com/auth/calendar.events'
      });
    });
  }

  async login() {
    await gapi.auth2.getAuthInstance().signIn();
    const signInEmail = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

    if (this.doctors.find(doctor => doctor.email === signInEmail)) {
      this.adminService.setAccessToken(accessToken);
      this.adminService.setEmail(signInEmail);
      await this.location.back();
    } else {
      this.notificationService.showErrorModal('Az email cím nem megfelelő.')
    }
  }
}
