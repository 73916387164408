<div class="content-space">
  <div *ngIf="event">
    A(z) <b>{{patientEmail}}</b> email címmel <b>{{event.start.dateTime | date: 'yyyy-MM-dd HH:mm'}}</b> órára történt
    foglalás.
  </div>
  <p>Biztosan szeretné lemondani?</p>
  <button class="primary-button mt-4" [disabled]="isLoading" (click)="cancelReservation()">Lemondás
    <app-loading *ngIf="isLoading" [isWhite]="true"></app-loading>
  </button>
</div>
