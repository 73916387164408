import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'angular-modal-simple';

@Component({
  selector: 'app-barbi-modal',
  templateUrl: './barbi-modal.component.html',
  styleUrls: ['./barbi-modal.component.scss']
})
export class BarbiModalComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalService.closeAll();
  }

  constructor(private router: Router, private modalService: ModalService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.router.navigate(['/munkatarsaink']);
  }

}
