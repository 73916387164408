<div class="content-space">
  <h2>Áraink</h2>
  <div class="divider"></div>
  <div class="price">
    <div class="row">
      <p>Első alkalom – betegvizsgálat,
        állapotfelmérés (45-60 perc)</p>
      <p>9000 Ft</p>
    </div>
    <div class="row">
      <p>Gyógytorna kezelés (terápia típusától függetlenül, <b>45 perc</b>)
        - a kezelés időtartama a beteg állapotától
        függően ettől eltérhet</p>
      <p>7500 Ft</p>
    </div>
    <div class="row">
      <p>Kinesiotape felhelyezés</p>
      <p>2000 Ft</p>
    </div>
    <div class="row">
      <p>Dynamic tape felhelyezés</p>
      <p>3000 Ft</p>
    </div>
    <div class="row">
      <p>Masszázs (60 perc)</p>
      <p>8000 Ft</p>
    </div>
  </div>
  <p class="addition-text">* Az árváltoztatás jogát fenntarjuk.</p>

  <h2>Általános információk</h2>
  <div class="divider"></div>
  <div class="more">
    <b>Mit hozzak magammal a kezelésre?</b>
    <ul>
      <li>Az első alkalommal- amennyiben rendelkezésre állnak<br/>
        o Orvosi leletek a jelenlegi problémával kapcsolatban<br/>
        o Dokumentáció a korábbi kezelésekről<br/>
        o Képalkotó vizsgálatok eredményei, szöveges leírás
      </li>
      <li>Törülköző</li>
      <li>Kényelmes öltözet – a tornatermen belül átöltözésre van lehetőség</li>
    </ul>
    <br/>
    <b>Mennyivel előbb szükséges érkeznem a kezelésre?</b>
    <p class="my-4">A csoportosulás elkerülése
      érdekében, a megadott időpont előtt maximum 5-10 perccel érkezzenek!</p>
    <br/>
    <b>Mi a teendő, ha mégsem tudok megjelenni a megbeszélt időponton?</b>
    <ul>
      <li>Kérjük mindenképp jelezze, a lehető leghamarabb, valamely elérhetőségünk egyikén.</li>
      <li>Amennyiben online foglalta időpontját, úgy azt az e-mailben elküldött link
        segítségével mondhatja le, az időpont előtt maximum 24 órával.
      </li>
    </ul>
    <br/>
    <b>COVID-19 járványhelyzettel kapcsolatos intézkedések:</b>
    <ul>
      <li> A kezelések megkezdése előtt kötelező a kézfertőtlenítés, melyhez a fertőtlenítő
        folyadékot biztosítjuk.
      </li>
      <li>Gyógytornászaink, mint egészségügyi dolgozók, oltással rendelkeznek.</li>
      <li> A kezelések között gyógytornászaink az eszközöket fertőtlenítik, annak érdekében,
        hogy a lehető legbiztonságosabb körülmények között történjenek a foglalkozások.
      </li>
      <li> Amennyiben légúti tüneteket, hőemelkedést, lázat tapasztal, kérjük jelezze kollegáink
        felé, valamely elérhetőségünk egyikén.
      </li>
    </ul>
  </div>

</div>
