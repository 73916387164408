import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayPipe'
})
export class DayPipePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'Monday':
        return 'Hétfő'
      case 'Tuesday':
        return 'Kedd'
      case 'Wednesday':
        return 'Szerda'
      case 'Thursday':
        return 'Csütörtök'
      case 'Friday':
        return 'Péntek'
      case 'Saturday':
        return 'Szombat'
      case 'Sunday':
        return 'Vasárnap'
      default:
        return value;
    }
  }

}
