import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

type Menu = '' | 'munkatarsaink' | 'kapcsolat' | 'informaciok' | 'szolgaltatasok' | 'foglalas' | 'blog' | 'videotar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAdmin: boolean = false;
  currentMenu: Menu = '';
  isOpen: boolean = false;

  constructor(private router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(
      event => {
        const url = (event as any).url.substring(1);
        this.selectMenu(url);
        this.isAdmin = url.includes('admin');
      }
    )
  }

  ngOnInit(): void {
  }

  selectMenu(menu: Menu) {
    this.currentMenu = menu || '';
    this.isOpen = false;
  }

}
