import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Title } from '@angular/platform-browser';
import {ServicesService} from "../core/services.service";
import {Service} from "../shared/types/types";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  contentIndex: number = 0;
  services: Service[] = [];

  constructor(private gaService: GoogleAnalyticsService, private titleService: Title, private servicesService: ServicesService) {
  }

  async ngOnInit() {
    this.titleService.setTitle('EREF fizioterápia - Szolgáltatások');
    this.gaService.pageView('/szolgaltatasok', 'Szolgáltatások');

   this.services = await this.servicesService.getServiceList();
  }

  showContent(index: number) {
    this.contentIndex === index ? this.contentIndex = 0 : this.contentIndex = index;
  }

}
