import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('EREF fizioterápia - Gyógytorna Székesfehérváron');
    this.gaService.pageView('/', 'Főoldal');
  }

}
